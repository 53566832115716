<template>
  <div class="classifyForm">
    <Form
      ref="formValidate"
      :model="formValidate"
      :rules="ruleValidate"
      :label-width="80"
    >
      <FormItem label="分类名称" prop="name">
        <Input v-model="formValidate.name" placeholder="请输入分类名称"></Input>
      </FormItem>
      <FormItem
        v-if="this.formValidate.type_status == '普通商品分类'"
        label="是否显示在导航栏"
        prop="audit"
      >
        <RadioGroup v-model="formValidate.audit">
          <Radio label="是"></Radio>
          <Radio label="否"></Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="分类类型" prop="type_status">
        <RadioGroup v-model="formValidate.type_status">
          <Radio label="普通商品分类"></Radio>
          <Radio label="数字化权益分类"></Radio>
        </RadioGroup>
      </FormItem>
      <div v-if="admin_type == 1">
        <FormItem label="选择商户">
          <Select v-model="formValidate.shop" placeholder="请选择">
            <Option
              :value="item.storeid"
              v-for="(item, index) in selects"
              :key="index"
              @click.native="addType(item.storeid)"
              >{{ item.s_name }}</Option
            >
          </Select>
        </FormItem>
      </div>
      <FormItem
        v-if="this.formValidate.type_status == '普通商品分类'"
        label="上级分类"
      >
        <Select v-model="formValidate.city" placeholder="请选择">
          <Option
            :value="item.gtid"
            v-for="(item, index) in list"
            :key="index"
            >{{ item.t_name }}</Option
          >
        </Select>
      </FormItem>
      <el-upload
        class="avatar-uploader"
        :action="uploadingUrl"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
      >
        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        <div slot="tip" class="el-upload__tip">
          分类图标只能上传jpg/png文件，且不超过500kb
        </div>
      </el-upload>
      <FormItem label="分类描述">
        <Input
          v-model="formValidate.desc"
          type="textarea"
          :autosize="{ minRows: 10, maxRows: 15 }"
          placeholder="分类描述"
        ></Input>
      </FormItem>
      <FormItem>
        <Button
          v-if="!isShow"
          type="primary"
          @click="handleSubmit('formValidate')"
          >提交</Button
        >
        <Button
          v-if="isShow"
          type="primary"
          @click="compileSubmit('formValidate')"
          >修改</Button
        >
        <Button @click="handleReset('formValidate')" style="margin-left: 8px"
          >重置</Button
        >
      </FormItem>
    </Form>
  </div>
</template>

<script>
export default {
  name: "classifyForm",
  data() {
    return {
      uploadingUrl: "",
      imageUrl: "",
      list: "",
      admin_type: "",
      selects: [],
      formValidate: {
        name: "",
        city: "",
        desc: "",
        audit: "是",
        shop: "",
        type_status: "普通商品分类",
      },
      ruleValidate: {
        name: [
          {
            required: true,
            message: "请输入分类名称",
            trigger: "blur",
          },
        ],
        type_status: [
          {
            required: true,
            message: "请输入分类名称",
            trigger: "blur",
          },
        ],
      },
      logo: "",
      isShow: false,
    };
  },
  created() {
    var admin_type = this.$storage.getLocal("type");
    this.admin_type = admin_type;
    let token = this.$storage.getLocal("token");
    this.uploadingUrl = this.$request.imgUrl() + "PHPExcel/fileUpload";
    if (admin_type == "1") {
      this.$request.getMerchantData({ token }).then((r) => {
        console.log(123);
        if (r.code == 0) {
          this.selects = r.data;
          console.log(r);
        }
      });
	  this.$request
        .addTypeList({
          token,
          pstoreid: "",
          login_type: this.admin_type,
        })
        .then((res) => {
          console.log(res);
          this.list = res.data;
        });
      if (this.$request.getUrl("id")) {
        this.isShow = true;
        let id = this.$request.getUrl("id");
        this.$request
          .typeOneData({
            token,
            id,
          })
          .then((res) => {
            this.compile = res.data;
            this.formValidate.name = res.data.t_name;
            this.formValidate.city = res.data.pid;
            this.formValidate.shop = res.data.storeid;
            this.formValidate.desc = res.data.tcontent;
            this.formValidate.audit = res.data.is_display == 1 ? "是" : "否";
            this.formValidate.type_status = res.data.type_status == 1 ? "普通商品分类" : "数字化权益分类";
            this.logo = res.data.tlogo;
            this.imageUrl =
              this.$request.imgUrl2() + "uploads/" + res.data.tlogo;
          });
      }
    }
    else {
      this.$request
        .addTypeList({
          token,
          pstoreid: "",
          login_type: this.admin_type,
        })
        .then((res) => {
          console.log(res);
          this.list = res.data;
        });
      if (this.$request.getUrl("id")) {
        this.isShow = true;
        let id = this.$request.getUrl("id");
        this.$request
          .typeOneData({
            token,
            id,
          })
          .then((res) => {
            this.compile = res.data;
            this.formValidate.name = res.data.t_name;
            this.formValidate.city = res.data.pid;
            this.formValidate.desc = res.data.tcontent;
            this.formValidate.audit = res.data.is_display == 1 ? "是" : "否";
            this.logo = res.data.tlogo;
            this.imageUrl =
              this.$request.imgUrl2() + "uploads/" + res.data.tlogo;
          });
      }
    }

    // console.log(123)
  },
  methods: {
    compileSubmit(name) {
      console.log(name);
      this.$refs[name].validate((valid) => {
        if (valid) {
          let id = this.$request.getUrl("id");
          let token = this.$storage.getLocal("token");
          this.$request
            .upTypeData({
              token,
              id,
              name: this.formValidate.name,
              pstoreid: this.formValidate.shop,
              login_type: this.admin_type,
              pid: this.formValidate.city,
              display: this.formValidate.audit == "是" ? 1 : 2,
              type_status:this.formValidate.type_status == "普通商品分类" ? 1 : 2,              tcontent: this.formValidate.desc,
              tlogo: this.logo,
            })
            .then((res) => {
              if (res.code == 0) {
                this.$Message.success("编辑成功!");
                this.handleReset("formValidate");
                this.$router.push(
                  "/commodityClassify",
                  function () {},
                  function () {}
                );
              } else {
                this.$Message.error("编辑失败!");
              }
            });
        } else {
          this.$Message.error("编辑失败!");
        }
      });
    },
    addType(value) {
      let token = this.$storage.getLocal("token");
      var admin_type = this.$storage.getLocal("type");
      this.admin_type = admin_type;
      this.$request
        .addTypeList({
          token,
          pstoreid: value,
          login_type: admin_type,
        })
        .then((res) => {
          this.list = res.data;
        });
      if (this.$request.getUrl("id")) {
        this.isShow = true;
        let id = this.$request.getUrl("id");
        this.$request
          .typeOneData({
            token,
            id,
          })
          .then((res) => {
            this.compile = res.data;
            this.formValidate.name = res.data.t_name;
            this.formValidate.city = res.data.pid;
            this.formValidate.desc = res.data.tcontent;
            this.formValidate.audit = res.data.is_display == 1 ? "是" : "否";
            this.logo = res.data.tlogo;
            this.imageUrl =
              this.$request.imgUrl2() + "uploads/" + res.data.tlogo;
          });
      }
    },
    handleSubmit(name) {
      console.log(this.admin_type);
      // return false
      console.log(this.formValidate.shop);
      var pid = "";
      if (this.formValidate.shop) {
        var pid = this.formValidate.shop;
      }
      this.$refs[name].validate((valid) => {
        if (valid) {
          let token = this.$storage.getLocal("token");
          this.$request
            .typeAddData({
              token,
              name: this.formValidate.name,
              pstoreid: pid,
              login_type: this.admin_type,
              id: this.formValidate.city,
              display: this.formValidate.audit == "是" ? 1 : 2,
              tcontent: this.formValidate.desc,
              tlogo: this.logo,
              type_status:
                this.formValidate.type_status == "普通商品分类" ? 1 : 2,
            })
            .then((res) => {
              // console.log(res)
              if (res.code == 0) {
                this.$Message.success("添加成功!");
                this.handleReset("formValidate");
                this.$router.push(
                  "/commodityClassify",
                  function () {},
                  function () {}
                );
              } else {
                console.log(111);
                this.$Message.error("添加失败!");
              }
            });
        } else {
          console.log(222);
          this.$Message.error("添加失败!,请先选择商户");
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      this.imageUrl = "";
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.logo = res.data.src;
    },
    beforeAvatarUpload(file) {
      if (
        file.type === "image/png" ||
        file.type === "image/gif" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg"
      ) {
        const isJPG = file.type;
      }
      const isJPG = file.type;
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style scoped="scoped">
.classifyForm {
  padding-left: 200px;
  width: 50%;
  margin: 0 auto;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 58px;
  height: 58px;
  line-height: 58px;
  text-align: center;
}

.avatar {
  width: 58px;
  height: 58px;
  display: block;
}

.avatar-uploader-icon {
  border: 1px solid #333333;
}
</style>

<template>
	<div class="addClassify">
		<frame :top1="top1" :top2="top2">
			<froms></froms>
		</frame>
	</div>
</template>

<script>
	import frame from '../../public/Frame.vue';
	import froms from './ClassifyForm.vue';
	export default {
		name: 'addClassify',
		data() {
			return {
				top1: '4-3',
				top2: ['4']
			}
		},
		components: {
			frame,
			froms
		},
		created() {
			
		}
	}
</script>

<style scoped="scoped">
	.addClassify{
	}
</style>
